import React, { Component } from 'react'
import Filters from './components/Filters/Filters'
import 'antd/dist/antd.css'
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'
import './App.css'


class App extends Component {
  render() {
    return (
      <Router>
        <div>
        <Route exact path="/" component={Filters} />
        <Route exact path="/all" component={Filters} />
        <Route exact path="/region/:id" component={Filters} />
        </div>
      </Router>
    )
  }
}

export default App
