import config from '../../config.json'
import React, { Component } from 'react'
import Profile from '../Profile/Profile'
import _ from 'underscore'
import axios from 'axios'
import './Filters.css'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import Map from '../Map/Map'
import { Slider, Select, Row, Button, Col, Icon, Menu, Dropdown, Switch, Spin } from 'antd'
const ReactHighcharts = require('react-highcharts')
const { Option } = Select
const moment = extendMoment(Moment);

  /*
  * - Filters component
  * - Loads conflict data from S3
  */


  const chartConfig = {
    chart: {
            type: 'scatter',
            backgroundColor: 'transparent',
            height: 300,
            style: {
              fontFamily: 'Questrial'
            }
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        tooltip: {
          formatter: function() {
            return `${this.point.properties.name}`
          }
        },
        xAxis: {
            title: {
                enabled: true,
                text: 'text',
                style: {
                  color: '#ccc'
                }
            },
            lineColor: '#ccc',
            tickColor: '#ccc',
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true
        },
        yAxis: {
            gridLineColor: '#ccc',
            title: {
                text: 'text',
                style: {
                  color: '#ccc'
                }
            }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        plotOptions: {
            series: {
                 animation: false,
                 point: {
                    events: {
                        mouseOver: function () {
                          //console.log(this)
                          //showLocation(this.name)
                        },
                        mouseOut: function () {
                          //console.log(this)
                          //showLocationOff()
                        }
                    }
                }
             },
            scatter: {
                marker: {
                    radius: 6,
                    lineColor: '#F7661C',
                    lineWidth: 1,
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                },
            }
        },
      series: [{
      name: 'Orgs',
      color: 'rgba(254, 142, 17, 0.5)',
      data: []
    }]
  }


  const start = moment(config.conflictData.start, 'MM/DD/YYYY')
  const end = moment(config.conflictData.end, 'MM/DD/YYYY')
  const range = moment.range(start, end)
  const arrayOfDates = Array.from(range.by('days'))
  const conflictDates = []
  _.each(arrayOfDates, function(date) {
    conflictDates.push(date.valueOf())
  })



  export default (class Filters extends Component {
    constructor(props) {
      super(props)
      this.state = {
        countries: [],
        lng: 30,
        lat: 20,
        zoom: 2,
        min: conflictDates[0],
        max: conflictDates[conflictDates.length-1],
        conflicts: {
          type: "FeatureCollection",
          features: []
        },
        conflictFilters: ['all', ['!has', 'null']],
        orgFilter: 'reset',
        orgChartData: [],
        orgDetails: [],
        conflictDetails: [],
        conflictField: '',
        conflictValue: '',
        conflictFieldOpp: '!=',
        showOrgs: true,
        showConflicts: true,
        chartConfig: chartConfig,
        'axis-x': 'established',
        'axis-y': 'assessmentScore',
        'axis-x-label': 'Established',
        'axis-y-label': 'Assessment Score'
      }
      this.queryRegions()
      this.queryData()

    }


    componentDidUpdate(prevProps, prevState) {
      var _self = this
      if (this.state['axis-x'] !== prevState['axis-x'] ||
         this.state['axis-y'] !== prevState['axis-y']) {
        this.updateOrgChart()
      }
      if (this.state.conflictValue !== prevState.conflictValue) {
        this.filterConflicts().then((data)=> {
          this.setState({
            conflicts: data
          })
        })
      }
      if (this.state.allConflicts && this.props.match.params.id !== prevState.activeCountry) {
          _.each(this.state.countries, function(country) {
            if (country.code === _self.props.match.params.id) {
              _self.setState({
                lng: parseFloat(country.lng),
                lat: parseFloat(country.lat),
                zoom: parseFloat(country.zoom),
                activeCountry: country.code,
                countryName: country.name,
                conflictValue: 'all',
                typeField: country['type-field'],
                orgFilter: 'reset',
                conflictDetails: null,
                orgDetails: null
              })
            }
          })
          this.filterConflicts().then((data)=> {
            this.setState({
              conflicts: data
            })
          })
      }
    }

    queryRegions() {
      var _self = this
      axios.get(`${config.apiBaseUrl}/regions/countries.json`)
      .then(function(response) {
        //console.log(response)
        var countries = _.sortBy(response.data, 'name')
        _self.setState({
          countries: countries
        })
      })
    }


    queryData() {
      var _self = this
      axios.all([
        axios.get(`${config.apiBaseUrl}/conflict/africa/acled-africa-2019.json`),
        axios.get(`${config.apiBaseUrl}/conflict/asia/acled-asia-2019.json`),
        axios.get(`${config.apiBaseUrl}/conflict/middle-east/acled-meast-2019.json`),
        axios.get(`${config.apiBaseUrl}/conflict/europe/acled-europe-2019.json`)
      ])
      .then(function(response) {
        console.log(response)
        var conflicts = _self.state.conflicts
        _.each(response[0].data.features, function(feature) {
          const dateSplit = feature.properties['event-date'].split(' ')
          const date = `${dateSplit[1]} ${dateSplit[0]}, ${dateSplit[2]}`
          feature.properties.timestamp = moment(date, 'MMMM DD, YYYY').valueOf()
          conflicts.features.push(feature)
        })
        _.each(response[1].data.features, function(feature) {
          const dateSplit = feature.properties['event-date'].split(' ')
          const date = `${dateSplit[1]} ${dateSplit[0]}, ${dateSplit[2]}`
          feature.properties.timestamp = moment(date, 'MMMM DD, YYYY').valueOf()
          conflicts.features.push(feature)
        })
        _.each(response[2].data.features, function(feature) {
          const dateSplit = feature.properties['event-date'].split(' ')
          const date = `${dateSplit[1]} ${dateSplit[0]}, ${dateSplit[2]}`
          feature.properties.timestamp = moment(date, 'MMMM DD, YYYY').valueOf()
          conflicts.features.push(feature)
        })
        _.each(response[3].data.features, function(feature) {
          const dateSplit = feature.properties['event-date'].split(' ')
          const date = `${dateSplit[1]} ${dateSplit[0]}, ${dateSplit[2]}`
          feature.properties.timestamp = moment(date, 'MMMM DD, YYYY').valueOf()
          conflicts.features.push(feature)
        })
        // eslint-disable-next-line
        var newObj = new Object()
        newObj = Object.assign({}, conflicts)
        _self.setState({
          conflicts: newObj,
          allConflicts: newObj
        })
      })
    }


    handleCountrySelect(value, e) {
      var country = e.props['data-code']
      var all = e.props['data-all']
      if (country === 'all') {
        this.resetMap()
      } else {
        this.props.history.push(`/region/${country}`)
      }
      if (!(all)) {
        this.setState({
          activeCountryName: value,
          showAll: false
        })
      }
      if (all) {
        this.setState({
          showAll: true
        })
      }
    }

    filterConflicts() {
      var _self = this
      var geoJSON = {
        type: "FeatureCollection",
        features: []
      }

      return new Promise((resolve, reject) => {
      if (this.state.conflictFieldOpp === '!=' && this.state.activeCountry) {
      _.each(this.state.allConflicts.features, function(feature) {
        if (parseFloat(feature.properties.timestamp) > _self.state.min &&
            parseFloat(feature.properties.timestamp) < _self.state.max &&
            feature.properties['iso-a3'] === _self.state.activeCountry
          ) {
          geoJSON.features.push(feature)
        }
      })
        resolve(geoJSON)
      }

      if (this.state.conflictFieldOpp === '==' && this.state.activeCountry) {
        if (this.state.conflictValue === 'all') {
          _.each(this.state.allConflicts.features, function(feature) {
            if (feature.properties.timestamp > _self.state.min &&
                feature.properties.timestamp < _self.state.max &&
                feature.properties['iso-a3'] === _self.state.activeCountry
              ) {
              geoJSON.features.push(feature)
            }
          })
          resolve(geoJSON)
        } else {
          _.each(this.state.allConflicts.features, function(feature) {
            if (feature.properties.timestamp > _self.state.min &&
                feature.properties.timestamp < _self.state.max &&
                feature.properties['iso-a3'] === _self.state.activeCountry &&
                feature.properties[_self.state.conflictField] === _self.state.conflictValue
              ) {
              geoJSON.features.push(feature)
            }
          })
          resolve(geoJSON)
        }
      }
    })
    }

    resetMap(e) {
      this.setState({
        lng: 30,
        lat: 20,
        zoom: 2,
        orgFilter: 'none'
      })
    }

    setConflictDetails(data) {
      this.setState({
        conflictDetails: data,
        orgDetails: null
      })
    }

    setOrgDetails(data) {
      var _self = this
      var chartData = []
      var config = this.state.chartConfig
      _.each(data, function(row) {
        // set these fields in state
        // -- whre to get options from - Config?
        var xValue = row.properties[_self.state['axis-x']]
        var yValue = row.properties[_self.state['axis-y']]
        if (xValue) {
          chartData.push({
            name: row.properties.name,
            properties: row.properties,
            x: parseFloat(xValue),
            y: parseFloat(yValue),
            point: row.geometry.coordinates
          })
        }
      })
      config.yAxis.title.text = _self.state['axis-y-label']
      config.xAxis.title.text = _self.state['axis-x-label']
      config.series[0].data = chartData
      this.setState({
        orgDetails: data,
        orgChartData: chartData,
        chartConfig: config,
        conflictDetails: null
      })
    }

    updateOrgChart() {
      var _self = this
      var data = this.state.orgDetails
      var chartData = []
      var config = this.state.chartConfig
      _.each(data, function(row) {
        // set these fields in state
        // -- whre to get options from - Config?
        var xValue = row.properties[_self.state['axis-x']]
        var yValue = row.properties[_self.state['axis-y']]
        if (xValue) {
          chartData.push({
            name: row.properties.name,
            properties: row.properties,
            x: _self.translateValues(xValue),
            y: _self.translateValues(yValue),
            point: row.geometry.coordinates
          })
        }
      })
      config.yAxis.title.text = _self.state['axis-y-label']
      config.xAxis.title.text = _self.state['axis-x-label']
      config.series[0].data = chartData
      this.setState({
        chartConfig: config
      })
    }

    translateValues(value) {
      if (config.translateValues[value]) {
        return config.translateValues[value]
      } else {
        return parseFloat(value)
      }
    }

    hideDetails(e) {
      e.preventDefault()
      this.setState({
        conflictDetails: null
      })
    }

    hideOrgDetails(e) {
      e.preventDefault()
      this.setState({
        orgDetails: null
      })
    }

    toggleOrgs(value) {
      this.setState({
        showOrgs: !!value
      })
    }

    toggleConflicts(value) {
      if (value === true) {
        this.setState({
          showConflicts: true,
          zoom: 5
        })
      } else {
        this.setState({
          showConflicts: false,
          zoom: this.state.zoom
        })
      }
    }

    handleRange(value) {
      var min = conflictDates[value[0]]
      var max = conflictDates[value[1]-1]
      this.setState({
        min: min,
        max: max
      })
      this.filterConflicts().then((data)=> {
        this.setState({
          conflicts: data
        })
      })
    }


    handleInputChange(e) {
       const target = e.target;
       const value = target.type === 'checkbox' ? target.checked : target.value;
       const name = target.name;
       this.setState({
         [name]: value
       });
     }

    handleOrgFilter(e) {
      var value = e.target.getAttribute('data-value')
      var field = e.target.getAttribute('data-field')
      //console.log(value)
      this.setState({
        orgFilter: value,
        orgField: field
      })
    }

    handleConflictFilter(e) {
      var value = e.target.getAttribute('data-value')
      var field = e.target.getAttribute('data-field')
      this.setState({
        conflictField: field,
        conflictValue: value,
        conflictFieldOpp: '=='
      })
    }

    handleGraphField(value) {
      var option = value.domEvent.target.getAttribute('data-value')
      var axis = value.domEvent.target.getAttribute('data-axis')
      var label = value.domEvent.target.getAttribute('data-label')
      //chartConfig.chart[`${axis}Axis`].title.text = option
      this.setState({
        [`axis-${axis}`]: option,
        [`axis-${axis}-label`]: label
      })
    }

    handleOrgGraphOptions(axis) {
      const menu = (
      <Menu>
        { config.graphFields.COD.map((option, i) =>
          <Menu.Item
           onClick={ this.handleGraphField.bind(this) }
           key={ `org-filter-${i}`}
           >
            <button className="axis-button" data-label={ option.name }data-axis={ axis } data-value={ option.value }>{ option.name }</button>
          </Menu.Item>
        )}
      </Menu>
    )
     return menu
    }

    tipFormat(value) {
      var ts = conflictDates[value-1]
      if (!(ts)) {
        ts = conflictDates[value]
      }
      var date = moment(ts).format('MMMM DD, YYYY')
      return(date)
    }

    setOrgsFilterOptionsFocus(data) {
      this.setState({
        orgsFilterOptionsFocus: data
      })
    }

    setConflictFilterOptions(data) {
      this.setState({
        conflictFilterOptions: data
      })
    }

    setConflictSubFilterOptions(data) {
      this.setState({
        conflictSubFilterOptions: data
      })
    }

    setConflictActorFilterOptions(data) {
      this.setState({
        conflictActorFilterOptions: data
      })
    }

    translateTerm(term) {
      if (config.translateTerms[term]) {
        return config.translateTerms[term]
      } else {
        return term
      }
    }

    render() {
      if (this.state.allConflicts) {
      return (
       <>
       <div className="filters">
         <div className="content">
         <div className="regions">
          <div className="filter-header">
            <button>Regions</button>
          </div>
          <div className="filter-content">

          <Select style={{ width: 240 }}
           showSearch
           placeholder="Select Region"
           value={ this.state.countryName }
           onChange={ this.handleCountrySelect.bind(this) }
          >
          <Option value="all" data-code="all">All regions</Option>
        { this.state.countries.map((country, i) =>
           <Option
             key={i}
             value={ country.name }
             data-lat={country.lat}
             data-lng={country.lng}
             data-all={country.showAll}
             data-type-field={country['type-field']}
             data-code={country.code}
             data-zoom={country.zoom}
         >
         { country.name}
         </Option>
         )}
        </Select>
          </div>
         </div>

         <div className="orgs">
           <div className="filter-header">
             <button>Organizations</button>
           </div>
            <div className="filter-content">
            <Row className="org-switch">
            <Col span={21}>
              Show organizations
            </Col>
            <Col span={3}>
           <Switch
             size="small"
             onChange={ this.toggleOrgs.bind(this) }
             checked={ this.state.showOrgs }
           />
           </Col>
           </Row>

         { this.state.orgsFilterOptionsFocus && this.state.orgsFilterOptionsFocus.length > 0 &&
           <div className="pi-menu">
            <div className="pi-menu-header">
              Primary Focus
            </div>
            <div className="pi-menu-content">
            <button
            onClick={ this.handleOrgFilter.bind(this) }
            data-field="all"
            data-value="reset"
            className={ this.state.orgFilter === "all" ? 'active' : undefined }
            key="all">
             All
            </button>
           { this.state.orgsFilterOptionsFocus && this.state.orgsFilterOptionsFocus.map((option, i) =>
             <button
             onClick={ this.handleOrgFilter.bind(this) }
             data-field={ option.field }
             data-value={ option.name }
             className={ this.state.orgFilter === option.name ? 'active' : undefined }
             key={i}>
                 { this.translateTerm(option.name) } ({option.count})
             </button>
           )}
           </div>
           </div>
          }
          </div>
         </div>

         <div className="conflict">
           <div className="filter-header conflict-header">
             <button>Conflicts</button>
           </div>
           <div className="filter-content">
          <Row className="conflict-switch">
           <Col span={21}>
           Show conflicts
           </Col>
           <Col span={3}>
           <Switch
             size="small"
             onChange={ this.toggleConflicts.bind(this) }
             checked={ this.state.showConflicts }
           />
           </Col>
           </Row>
            Date range
           <Slider
             range
             onChange={ this.handleRange.bind(this) }
             min={ 0 }
             tipFormatter={ this.tipFormat }
             max={ conflictDates.length }
             defaultValue={[0, conflictDates.length]}
            />
           <div className="pi-menu">
             <div className="pi-menu-header">
               Primary event type
             </div>
             <div className="pi-menu-content">
             <button
             onClick={ this.handleConflictFilter.bind(this) }
             data-field="all"
             data-value="all"
             className={ this.state.conflictFilter === 'all' ? 'active' : undefined }
             key={'all'}>
               All
             </button>
           { this.state.conflictFilterOptions && this.state.conflictFilterOptions.map((option, i) =>
             <button
               onClick={ this.handleConflictFilter.bind(this) }
               data-value={ option.name }
               data-field="event-type"
               className={ this.state.conflictFilter === option.name ? 'active' : undefined }
               key={i}>
                { option.name } ({option.count})
              </button>
           )}
           </div>
          </div>
          <div className="pi-menu">
            <div className="pi-menu-header">
              Secondary event type
            </div>
            <div className="pi-menu-content">
            <button
            onClick={ this.handleConflictFilter.bind(this) }
            data-field="all"
            data-value="all"
            className={ this.state.conflictFilter === 'all' ? 'active' : undefined }
            key={'all'}>
              All
            </button>
          { this.state.conflictSubFilterOptions && this.state.conflictSubFilterOptions.map((option, i) =>
            <button
              onClick={ this.handleConflictFilter.bind(this) }
              data-value={ option.name }
              data-field="sub-event-type"
              className={ this.state.conflictFilter === option.name ? 'active' : undefined }
              key={i}>
               { option.name } ({option.count})
             </button>
          )}
           </div>
          </div>
         </div>
         </div>


         <div className="actors">
           <div className="filter-header conflict-header">
             <button>Actors</button>
           </div>
           <div className="filter-content">
           <div className="pi-menu">
             <div className="pi-menu-header">
               Primary Actors
             </div>
             <div className="pi-menu-content">
             <button
             onClick={ this.handleConflictFilter.bind(this) }
             data-field="all"
             data-value="all"
             className={ this.state.conflictFilter === 'all' ? 'active' : undefined }
             key={'all'}>
               All
             </button>
           { this.state.conflictActorFilterOptions && this.state.conflictActorFilterOptions.map((option, i) =>
             <button
               onClick={ this.handleConflictFilter.bind(this) }
               data-value={ option.name }
               data-field="actor-1"
               className={ this.state.conflictFilter === option.name ? 'active' : undefined }
               key={i}>
                { option.name } ({option.count})
              </button>
           )}
           </div>
           </div>
          </div>
        </div>

        </div>
        </div>


        { this.state.conflictDetails && this.state.conflictDetails.length > 0 &&
         <div className="conflict-details">
           <button
             className="btn-close"
             onClick={ this.hideDetails.bind(this) }
           >
            x
           </button>
           <div className="content">
           { this.state.conflictDetails.map((conflict, i) =>
             <div className="item" key={ i }>{ conflict.properties.description }</div>
           )}
           </div>
         </div>
        }

        { this.state.orgDetails && this.state.orgDetails.length > 0 &&
         <div className="org-details">
           <button
             className="btn-close"
             onClick={ this.hideOrgDetails.bind(this) }
           >
            x
           </button>
           <div className="content">
           <div className="organization-attributes">
             <div className="title">Organizations</div>
             { this.state.orgChartData && this.state.orgChartData.length > 0 &&
               <>
               <div className="axis-controls">
               <Dropdown overlay={ this.handleOrgGraphOptions('x') }>
                  <Button
                    size="small"
                  >x-Axis <Icon type="down" /></Button>
                </Dropdown>
                <Dropdown overlay={ this.handleOrgGraphOptions('y') }>
                  <Button
                    size="small"
                   >y-Axis <Icon type="down" /></Button>
                 </Dropdown>
                 </div>
             <div className="organization-chart">
               <ReactHighcharts ref='chart' domProps={{className: 'org-chart'}} config={this.state.chartConfig }></ReactHighcharts>
               <p>Source: Peace Direct</p>
             </div>
             </>
             }
           </div>
           { this.state.orgDetails && this.state.orgDetails.map((org, i) =>
             <div className="item" key={ `org-item-${i}` }>
             { org.properties.name }<br/>
             { org.properties.link &&
               <a target="parent" href={ 'https://peaceinsight.org' + org.properties.link }>View Profile</a>
             }
             </div>
           )}
           </div>
         </div>
       }

       <Map
         lng={ this.state.lng }
         lat={ this.state.lat }
         min={ this.state.min }
         max={ this.state.max }
         zoom={ this.state.zoom }
         showAll={ this.state.showAll }
         activeCountry={ this.state.activeCountry }
         orgsFilterOptionsFocus={ this.setOrgsFilterOptionsFocus.bind(this) }
         orgFilters={ this.state.orgFilters }
         conflictDetails={ this.setConflictDetails.bind(this) }
         conflictFilterOptions={ this.setConflictFilterOptions.bind(this) }
         conflictSubFilterOptions={ this.setConflictSubFilterOptions.bind(this) }
         conflictActorFilterOptions={  this.setConflictActorFilterOptions.bind(this) }
         conflicts={ this.state.conflicts }
         orgDetails={ this.setOrgDetails.bind(this) }
         conflictFilters={ this.state.conflictFilters }
         orgFilter={ this.state.orgFilter }
         orgField={ this.state.orgField }
         showOrgs={ this.state.showOrgs }
         showConflicts={ this.state.showConflicts }
       />
      { !(this.state.activeCountry) &&
       <div className="mask">
         <div className="text">
           Select a country or conflict to start Peace Insight.
         </div>
       </div>
       }
       <Profile
        countryName ={ this.state.countryName }
        conflicts={ this.state.conflicts }
       />
       </>
      )

    } else {
      return (
      <div className="loading">
        <div className="loader"><Spin tip="Loading..." size="large"/></div>
      </div>
     )

    }
   }
  })
