import config from '../../config.json'
import React, { Component } from 'react'
import ReactMapboxGl, { Layer, Source, ZoomControl, /*Popup*/  } from "react-mapbox-gl"
import axios from 'axios'
import './Map.css';
import _ from 'underscore'
const Map = ReactMapboxGl({
  injectCSS: true,
  accessToken: "pk.eyJ1IjoicGVhY2VpbnNpZ2h0IiwiYSI6ImNqbm9nMHFvNjA1MnQzdm0xaWNxM3l5d3YifQ.pXF7u303bNopP7uyVBK8tA"
});


/*
* - Map component
* - Loads orgs from S3
*/

export default (class peacemap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      center: [props.lng, props.lat],
      zoom: [props.zoom]
    }
    this.queryOrganizations()
  }

  componentDidMount() {
    if (this.props.conflicts) {
      this.getConflictFilterOptions('event-type', 'conflictFilterOptions')
      this.getConflictFilterOptions('sub-event-type', 'conflictSubFilterOptions')
      this.getConflictFilterOptions('actor-1', 'conflictActorFilterOptions')
    }
  }

  onMapLoad(map) {
    this.setState({ map: map })
  }

  queryOrganizations() {
    var _self = this
    axios.get(`${config.apiBaseUrl}/organizations/organizations.json`)
    .then(function(response) {
      _self.setState({
        orgs: response.data,
        orgsList: response.data
      })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    var _self = this
    if (prevProps.lng !== this.props.lng) {
      this.flyToLocation([this.props.lng, this.props.lat], this.props.zoom)
    }
    if (this.props.showConflicts === true && prevProps.showConflicts === false) {
      if (this.state.map.getZoom() < 5) {
        this.state.map.zoomTo(this.props.zoom)
      }
    }
    if (this.state.orgs && this.props.activeCountry !== prevProps.activeCountry) {
      this.filterOrgData()
    }
    if (this.props.orgFilter !== prevProps.orgFilter) {
      this.filterOrgData()
    }
    if (!(prevProps.activeCountry) && this.props.activeCountry && !(this.props.showAll)) {
      setTimeout(function(){
        _self.filterOrgData()
      }, 2000)
    }
    if (this.props.conflicts.features.length !== prevProps.conflicts.features.length) {
        //this.filterOrgData()
      this.getConflictFilterOptions('event-type', 'conflictFilterOptions')
      this.getConflictFilterOptions('sub-event-type', 'conflictSubFilterOptions')
      this.getConflictFilterOptions('actor-1', 'conflictActorFilterOptions')
    }
  }


  filterOrgData() {
    var _self = this
    var orgs = {
      type: "FeatureCollection",
      features: []
    }
    if (this.props.orgFilter === 'none' || this.props.showAll) {
      orgs = this.state.orgsList
    } else if (this.props.orgFilter !== 'reset') {
        _.each(this.state.orgsList.features, function(feature) {
          if (feature.properties.country === _self.props.activeCountry &&
              feature.properties[_self.props.orgField] === _self.props.orgFilter) {
            orgs.features.push(feature)
          }
        })
    } else {
      _.each(this.state.orgsList.features, function(feature) {
        if (feature.properties.country === _self.props.activeCountry) {
          orgs.features.push(feature)
        }
      })
    }
    this.setState({
      orgs: orgs
    })
    this.getOrgFilterOptions()
  }

  getOrgFilterOptions() {
    var _self = this
    var type
    if (_self.props.activeCountry === 'COD') {
      type = 'focus'
    } else {
      type = 'type'
    }
    this.setState({
      orgFilterField: type
    })
    var propertiesObj = {
      [type]: {}
    }
    _.each(this.state.orgsList.features, function(row, i) {
      if (row.properties.country === _self.props.activeCountry) {
        //console.log(row.properties)
      if (row.properties[type]) {
        if (propertiesObj[type][row.properties[type]]) {
          propertiesObj[type][row.properties[type]].count = propertiesObj[type][row.properties[type]].count + 1
        } else {
          propertiesObj[type][row.properties[type]] = { name: row.properties[type], field: type, count: 1 }
        }
       }
      }
    })
    var propertiesArray = []
    _.each(propertiesObj[type], function(item) {
      propertiesArray.push({
        name: item.name,
        field: item.field,
        count: item.count
      })
    })
    propertiesArray = _.sortBy(propertiesArray, 'count').reverse()
    this.props.orgsFilterOptionsFocus(propertiesArray)
  }

  getConflictFilterOptions(type, propToUpdate) {
    var propertiesObj = {
      [type]: {}
    }
      _.each(this.props.conflicts.features, function(row, i) {
        //console.log(row.properties)
        if (row.properties[type]) {
          if (propertiesObj[type][row.properties[type]]) {
            propertiesObj[type][row.properties[type]].count = propertiesObj[type][row.properties[type]].count + 1
          } else {
            propertiesObj[type][row.properties[type]] = { name: row.properties[type], count: 1 }
          }
        }
      })
    var propertiesArray = []
    _.each(propertiesObj[type], function(item) {
      propertiesArray.push({
        name: item.name,
        count: item.count
      })
    })
    propertiesArray = _.sortBy(propertiesArray, 'count').reverse()
    this.props[propToUpdate](propertiesArray)
  }

  onClickLayer(e) {
    var features = this.state.map.queryRenderedFeatures(e.point, { layers: ['layer-conflicts'] })
    this.props.conflictDetails(features)
  }

  onOrgClick(e) {
    var _self = this
    var cluster = this.state.map.queryRenderedFeatures(e.point, { layers: ['layer-orgs'] })
    var point_count = cluster[0].properties.point_count
    if (point_count) {
      var clusterSource = this.state.map.getSource('source-orgs')
      clusterSource.getClusterLeaves(cluster[0].properties.cluster_id, point_count, 0, function(err, data) {
        _self.props.orgDetails(data)
      })
    } else {
      _self.props.orgDetails([cluster[0]])
    }
  }

  onOrgCountryClick(e) {
    var _self = this
    var cluster = this.state.map.queryRenderedFeatures(e.point, { layers: ['layer-orgs-country'] })
    var point_count = cluster[0].properties.point_count
    if (point_count) {
      var clusterSource = this.state.map.getSource('source-orgs')
      clusterSource.getClusterLeaves(cluster[0].properties.cluster_id, point_count, 0, function(err, data) {
        _self.props.orgDetails(data)
      })
    } else {
      _self.props.orgDetails([cluster[0]])
    }
  }


  flyToLocation(center, zoom) {
    this.setState({
      center: center,
      zoom: [zoom]
    })
  }


  resetOrgData() {
    var orgs = this.state.orgsList
    this.setState({
      orgs: orgs
    })
  }

  onMouseEnter(e) {
    let features = []
    if (this.state.map.getLayer('layer-orgs-country')) {
      features = this.state.map.queryRenderedFeatures(e.point, { layers: ['layer-orgs-country'] })
    }
    if (this.state.map.getLayer('layer-orgs')) {
      features = this.state.map.queryRenderedFeatures(e.point, { layers: ['layer-orgs'] })
    }
    if (this.state.map.getLayer('layer-conflicts')) {
      features = this.state.map.queryRenderedFeatures(e.point, { layers: ['layer-conflicts'] })
    }
    if (features[0]) {
      this.state.map.getCanvas().style.cursor = 'pointer'
    }
  }

  onMouseLeave(e) {
    this.state.map.getCanvas().style.cursor = ''
  }

  render() {
    if (this.state.orgsList && this.state.orgsList.features.length > 0) {
    return (
     <>
     <Map
     className={ 'pi-map' }
     center={this.state.center}
     zoom={this.state.zoom}
     onStyleLoad={this.onMapLoad.bind(this)}
     // eslint-disable-next-line
     style={ 'mapbox://styles/peaceinsight/cju8nk5a62cbt1fnyilzhtgqd' }
     containerStyle={{
          height: '100vh',
          width: '100hw'
        }}
     >
     <Source
      id="source-orgs"
      geoJsonSource={{
        type: "geojson",
        data: this.state.orgs,
        cluster: true,
        clusterRadius: 20
      }}
      />
      { this.props.conflicts &&
      <Source
       id="source-conflicts"
       geoJsonSource={{
         type: "geojson",
         data: this.props.conflicts
       }}
       />
     }
     { this.props.conflicts &&
       this.props.activeCountry &&
       this.props.showConflicts &&
     <Layer
       id="layer-conflicts"
       sourceId="source-conflicts"
       type="circle"
       minZoom= { 4 }
       before="layer-orgs-country"
       onMouseEnter={ this.onMouseEnter.bind(this) }
       onMouseLeave={ this.onMouseLeave.bind(this) }
       onClick={ this.onClickLayer.bind(this) }
       paint={{
         "circle-color": "#ED1B23",
         'circle-opacity': 0.3,
         'circle-stroke-color': "#ED1B23",
         'circle-stroke-width': 1,
         'circle-stroke-opacity': 0.5,
         'circle-blur': 0,
         'circle-radius': {
           'property': 'fatalities',
           'stops': [
             [0, 4],
             [20, 30]
           ]
         }
       }}
     />
     }
     { !(this.props.activeCountry) &&
       this.props.conflicts &&
       this.props.showConflicts &&
     <Layer
       id="layer-conflicts"
       sourceId="source-conflicts"
       type="circle"
       before="layer-orgs"
       minZoom= { 4 }
       onMouseEnter={ this.onMouseEnter.bind(this) }
       onMouseLeave={ this.onMouseLeave.bind(this) }
       onClick={ this.onClickLayer.bind(this) }
       paint={{
         "circle-color": "#ED1B23",
         'circle-opacity': 0.3,
         'circle-stroke-color': "#ED1B23",
         'circle-stroke-width': 1,
         'circle-stroke-opacity': 0.5,
         'circle-blur': 0,
         'circle-radius': {
           'property': 'fatalities',
           'stops': [
             [0, 2],
             [20, 30]
           ]
         }
       }}
     />
     }
     { this.props.activeCountry && this.props.showOrgs &&
       <Layer
         id="layer-orgs-country"
         sourceId="source-orgs"
         type="circle"
         onMouseEnter={ this.onMouseEnter.bind(this) }
         onMouseLeave={ this.onMouseLeave.bind(this) }
         onClick={ this.onOrgCountryClick.bind(this) }
         paint={{
           "circle-color": "#fff",
           'circle-opacity': 1,
           'circle-stroke-color': '#F98B11',
           'circle-stroke-width': 3,
           'circle-radius': [
             "step",
             ["get", "point_count"],
             15,
             100,
             15
           ]
         }}
       />
    }
    { !(this.props.activeCountry) && this.props.showOrgs &&
      <Layer
        id="layer-orgs"
        sourceId="source-orgs"
        type="circle"
        onMouseEnter={ this.onMouseEnter.bind(this) }
        onMouseLeave={ this.onMouseLeave.bind(this) }
        onClick={ this.onOrgClick.bind(this) }
        paint={{
          "circle-color": "#fff",
          'circle-opacity': 1,
          'circle-stroke-color': '#F98B11',
          'circle-stroke-width': 3,
          'circle-radius': [
            "step",
            ["get", "point_count"],
            15,
            100,
            15
          ]
        }}
      />
    }
    { this.props.activeCountry && this.props.showOrgs &&
      <Layer
        id="layer-orgs-count"
        sourceId="source-orgs"
        type="symbol"
        layout={{
          "text-field": "{point_count_abbreviated}",
          "text-allow-overlap": true,
          "text-size": 11
        }}
        paint={{
          "text-color": "#ED1B23"
        }}
      />
    }

      { !(this.props.activeCountry) && this.props.showOrgs &&
        <Layer
          id="layer-orgs-count"
          sourceId="source-orgs"
          type="symbol"
          layout={{
            "text-field": "{point_count_abbreviated}",
            "text-allow-overlap": true,
            "text-size": 11
          }}
          paint={{
            "text-color": "#ED1B23"
          }}
        />
      }
      <div className="zoom-cntrl">
        <ZoomControl />
      </div>
      </Map>
     </>
   )
   } else {
     return null
   }

}
})
