import React, { Component } from 'react'
import './Graph.css'
import moment from 'moment'
const ReactHighcharts = require('react-highcharts')


export default (class ConflictGraph extends Component {
  constructor(props) {
    super(props)
    this.config = {
      chart: {
            type: 'area',
            backgroundColor: 'transparent',
            height: 50,
            margin: [0,0,0,0],
            padding: [0,0,0,0],
            style: {
              fontFamily: 'Questrial'
            }
          },
          title: {
              text: ''
          },
          subtitle: {
              text: ''
          },
          tooltip: {
            formatter: function() {
              return ''
            }
          },
          xAxis: {
              type: 'datetime',
              crosshair: true,
              title: {
                  enabled: false,
                  text: 'text',
                  style: {
                    color: '#555'
                  }
              },
              lineColor: '#fff',
              gridLines: {
                enabled: false
              },
              tickColor: '#fff',
              showLastLabel: true
          },
          yAxis: {
              gridLineColor: '#fff',
              min: 0,
              title: {
                  enabled: false,
                  text: 'text',
                  style: {
                    color: '#555'
                  }
              }
          },
          legend: {
            enabled: false
          },
          credits: {
            enabled: false
          },
          plotOptions: {
              series: {
                   animation: false,
                   point: {
                      events: {
                          mouseOver: function () {
                            //console.log(this)
                            //showLocation(this.name)
                          },
                          mouseOut: function () {
                            //console.log(this)
                            //showLocationOff()
                          }
                      }
                  }
               },
               line: {
                 lineWidth: 2,
                 marker: {
                   enabled: false
                 }
               },
               area: {
                 fillColor: 'rgba(237, 27, 35, 0.2)',
                 marker: {
                   enabled: false
                 }
               }
          },
        series: [{
          name: 'Incidents',
          color: 'rgba(237, 27, 35, 0.8)',
          data: []
         },{
          name: 'Fatalities',
          color: 'rgba(0,0,0, 0.8)',
          data: []
        }]
    }
  }


  render() {
    var _self = this
    this.config.series[0].data = this.props.data1
    //this.config.series[1].data = this.props.data2
    //this.config.yAxis.title.text = this.props.yAxisLabel
    this.config.tooltip.formatter = function() {
      return moment(this.x).format('DD MMM YYYY') + '<br/>' + _self.props.label + ': ' + this.y
    }
    return(
      <div className="conflict-graph">
          <ReactHighcharts domProps={{className: 'chart'}} config={this.config}></ReactHighcharts>
      </div>
    )
  }
})
