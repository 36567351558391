import config from '../../config.json'
import React, { Component } from 'react'
import Graph from '../Graph/Graph'
import moment from 'moment'
import _ from 'underscore'
import './Profile.css'


/*
* Profile component
*/

export default (class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      conflicts: [],
      fatalities: 0,
      actors: []
    }
  }

  componentDidMount() {
    if (this.props.countryName !== null) {
      this.filterConflictData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.conflicts.features.length !== prevProps.conflicts.features.length) {
      this.filterConflictData()
    }
  }

  getDates(startDate, endDate) {
    var dates = {};
    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');
    while(currDate.add(1, 'days').diff(lastDate) < 0) {
      var date = currDate.clone().toDate()
      dates[parseFloat(moment(date).format('x'))] = { count: 0, fatalities: 0, date: parseFloat(moment(date).format('x')) }
    }
    return dates;
  }

  filterConflictData() {
    var _self = this
    var array = []
    var fatalities = 0
    var incidents = _self.getDates(moment(config.conflictData.start), moment(config.conflictData.end))
    var actors = {}

    _.each(this.props.conflicts.features, function(feature, index) {
      // if there is a single filter
          array.push(feature)

          // INCIDENTS
          var dateStr = String(feature.properties['event-date'])
          var dateSplit = dateStr.split(' ')
          var day = dateSplit[0]
          var month = dateSplit[1]
          var year = dateSplit[2]
          var date = moment(day + ' ' + month + ' ' + year).format('x')
          if (incidents[date] && incidents[date].count) {
            incidents[date].count = incidents[date].count + 1
            incidents[date].fatalities = incidents[date].fatalities + feature.properties.fatalities
          } else {
            incidents[date] = {
              date: date,
              count: 1,
              fatalities: feature.properties.fatalities
            }
          }

          // ACTORS
          if (actors[feature.properties['actor-1']]) {
            actors[feature.properties['actor-1']].count = actors[feature.properties['actor-1']].count + 1
          } else {
            actors[feature.properties['actor-1']] = {
              name: feature.properties['actor-1'],
              count: 1
            }
          }
          fatalities = fatalities + feature.properties.fatalities


    })
    var actorsArray = []
    _.each(actors, function(actor) {
      actorsArray.push(actor)
    })
    actorsArray = _.sortBy(actorsArray, 'count').reverse()


    var incidentsArray = []
    _.each(incidents, function(incident) {
      incidentsArray.push([parseFloat(incident.date), incident.count])
    })
    incidentsArray = _.sortBy(incidentsArray, 'date')

    var fatalitiesArray = []
    _.each(incidents, function(incident) {
      fatalitiesArray.push([parseFloat(incident.date), incident.fatalities])
    })
    fatalitiesArray = _.sortBy(fatalitiesArray, 'date')

    this.setState({
      conflicts: array,
      fatalities: fatalities,
      actors: actorsArray,
      incidents: incidentsArray,
      fatalityCounts: fatalitiesArray,
      profileLoaded: true
    })
  }

  handleBorderWidth(count) {
    var percent = count / this.state.conflicts.length * 100
    return percent + '%'
  }

  render() {
    if (this.state.profileLoaded) {
      return(
        <>
        { this.props.countryName &&
        <div className="profile">
            <div className="country">
              <h1>{ this.props.countryName }</h1>
            </div>
            { this.state.incidents &&
            <div className="incident-chart pi-chart">
              <Graph
                data1={ this.state.incidents }
                label="Incidents"
              />
            </div>
            }
            <div className="incident-count">{ this.state.conflicts.length } incidents <br/> Source: ACLED</div>
            { this.state.incidents &&
            <div className="incident-chart pi-chart">
              <Graph
                data1={ this.state.fatalityCounts }
                label="Fatalities"
              />
            </div>
            }
            <div className="fatalities-count">{ this.state.fatalities } fatalities <br/> Source: ACLED</div>
            <div className="actors-count">
              <h2>Primary Actors</h2>
              { this.state.actors.length === 0 &&
                <p>No actors found.</p>
              }
              { this.state.actors.map((actor, i) =>
                <div className="actor" key={i}>
                 <div className="percent-bar">
                  <div
                    className="bar"
                    style={{ width: this.handleBorderWidth(actor.count) }}
                  />
                  </div>
                  { actor.name } ({ actor.count })</div>
              )}
            </div>
        </div>
        }
        </>
      )
  } else {
    return null
  }
  }

})
